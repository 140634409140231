<template>
    <div class="_mb-pad-bottom-140 _pos-rel" style="background-color:rgba(247, 247, 247, 1);">
        <div class="_flex-row-center-mid _pos-rel _mb-pad-y-25 _white _mb-font-32" style="background-color:rgba(66, 132, 220, 1);position:sticky;top:7.99087vh;">
            <div class="_pos-abs _left _mb-pad-left-25 _flex-row-mid" @click="back">
                <img class="_mb-w-20 _mb-mar-right-10" src="~@/assets/image/hangda/fanhui1.png" alt="">
                <div class="_mb-font-28">{{$t('返回')}}</div>
            </div>
            <div style="line-height:1;">{{$t('地址管理')}}</div>
        </div>
        <div class="_mb-pad-x-30 _mb-pad-y-25">
            <div v-for="(item,index) in list" :key="index" class="_mb-mar-bottom-20 _w-max _white-bg" style="border-radius:6px" @click="back(item)">
                <div class="_mb-pad-x-25 _mb-pad-y-25 _border-bottom ">
                    <div class="_flex-row-mid _mb-mar-bottom-25" style="align-items:flex-end;">
                        <div class="_b _mb-font-32 _mb-mar-right-20">{{item.linkman}}</div>
                        <div class="_mb-font-28" style="color:rgba(66, 132, 220, 1);">{{item.mobile}}</div>
                    </div>
                    <div v-if="item.zip_code" class="_mb-mar-bottom-15">{{$t('邮编')}}：{{ item.zip_code }}</div>
                    <div class="_flex-row-mid _mb-font-28 _mb-mar-bottom-15">
                        <div>{{item.area}}</div>
                        <div class="_mb-pad-x-20 _grey-99">|</div>
                        <div>{{item.province}}{{item.city}}</div>
                    </div>
                    <div class="_mb-font-28">{{item.address}}</div>
                </div>
                <div class="_flex-row-mid _mb-pad-x-30 _mb-pad-y-25" style="justify-content:flex-end;">
                    <div @click.stop="del(item.id)" class="_mb-mar-left-25 _flex-row-center-mid _mb-w-200 _mb-h-60" style="color:rgba(200, 25, 32, 1);border:1px solid rgba(200, 25, 32, 1);border-radius:6px;">{{$t('删除')}}</div>
                    <div @click.stop="toDetail(item)" class="_mb-mar-left-25 _flex-row-center-mid _white _mb-w-200 _mb-h-60" style="border:1px solid rgba(66, 132, 220, 1);background-color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('修改地址')}}</div>
                </div>
            </div>
            <div v-if="list.length == 0" class="_mb-font-36 _b _mb-mar-y-45 _text-center">~{{$t('暂无数据')}}~</div>
        </div>
        <div class="_w-max _mb-display-block _display-none _mb-pad-x-30 _mb-pad-y-25 _white-bg _pos-fixed _bottom _left _z-index-2" style="color:rgba(66, 132, 220, 1);">
            <router-link :to="'/ChangeAddress'" class="_flex-row-center-mid _mb-pad-y-20" style="background-color:rgba(66, 132, 220, 0.2);border-radius:6px;">
                <div class="iconfont2 icon-jiahao _mb-font-32 _mb-mar-right-10"></div>
                <div>{{$t('添加地址')}}</div>
            </router-link>
        </div>
    </div>
</template> 

<script>
import { getReceiveAddressList, delAddress } from '@/api/jiyun.js'
export default {
    name: 'MobileAddresslist',
    data() {
        return {
            list:[],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        // 地址列表
        getList(){
            getReceiveAddressList().then(res=>{
                this.list = res.data;
            })
        },
        // 分页
        pageChange(index) {
            this.thisFrom.page = index
            this.getList()
        },
        // 删除
        del(id){
            let param = {
                address_id:id
            }
            let that = this;
            this.$Modal.confirm({
                title: this.$t('删除'),
                content: this.$t('删除该地址'),
                okText: this.$t('confirm'),
                cancelText: this.$t('cancel'),
                onOk(){
                    delAddress(param).then(res=>{
                        this.$Message.success('删除成功');
                        that.getList();
                    }).cath(res=>{
                        this.$Message.error(res.msg);
                    })
                }
            })
        },
        // 详情
        toDetail(data){
            this.$router.push({ 
                name: 'ChangeAddress',
                query: {
                    id: data.id,
                    linkman: data.linkman,
                    mobile: data.mobile,
                    area_id: data.area_id,
                    area: data.area,
                    province: data.province,
                    city: data.city,
                    address: data.address,
                    zip_code: data.zip_code
                }
            })
        },
        back(val) {
            sessionStorage.setItem('address',JSON.stringify(val))
            this.$router.go(-1)
        },
    },
};
</script>

<style lang="scss" scoped>

</style>